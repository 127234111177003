// import Slider from 'components/Slider';
import React from 'react';
import cn from 'classnames';
import styles from './Partners.module.scss';
import mobulaImage from '../../theme/images/partners/mobula.png';

const partners: { name: string; image: any; href: string }[] = [
  {
    name: 'Mobula',
    image: mobulaImage,
    href: 'https://mobula.fi/'
  }
];

const Partners = () => {
  return (
    <section className={cn(styles.wrapperPartners, 'container')}>
      <h2 className="partnersTitle">Partners</h2>
      <div className={styles.partners}>
        {partners.map((partner) => {
          return (
            <a className={styles.slideWrapper} href={partner.href} target="_blank" rel="noreferrer">
              <img src={partner.image} alt={`Logo ${partner.name}`} />
            </a>
          );
        })}
      </div>

      {/* when will be more then 5 partners remove code above and uncommit Slider */}

      {/* <Slider
        childrens={partners.map((partner) => {
          return (
            <a className={styles.slideWrapper} href={partner.href} target="_blank" rel="noreferrer">
              <img src={partner.image} alt={`Logo ${partner.name}`} />
            </a>
          );
        })}
      /> */}
    </section>
  );
};

export default Partners;
