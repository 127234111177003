import React, { useState, useEffect } from 'react';
import connectors from '../configWallet';
import { useDispatch } from 'react-redux';
import { sendLogin } from '../store/user/operations';
import { useNavigate } from 'react-router';
import { useEthers, shortenAddress, useBlockNumber } from '@usedapp/core';
import { wallets, connectorsByName, connectorsNames } from '../connectors';

function Login({ handleWallet }) {
  const { account, chainId, activateBrowserWallet, activate, deactivate } = useEthers();

  const { error } = useEthers();

  const [activateError, setActivateError] = useState('');

  const [activeOptions, setActiveOptions] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      if (!error.message.includes('1013') && !error.message.includes('missing')) {
        setActivateError(error.message);
      } else {
        setActivateError('');
      }
    }

    if (account && !activateError) {
      dispatch(sendLogin(shortenAddress(account), chainId, navigate));
    }
  }, [error, account, chainId, activateError]);

  const toggleWallet = (title) => {
    if (title == 'metamask') {
      activateBrowserWallet();
    } else if (title == 'walletconnect') {
      const provider = connectorsByName['walletconnect'];
      provider.enable();
      activate(provider);
    }

    setActivateError('');

    if (handleWallet) {
      handleWallet();
    }
  };

  const text = activeOptions ? 'Hide options' : 'Show more options';

  return (
    <>
      <p style={{ color: 'red' }}>{activateError}</p>
      <br />
      <div className={`innerAllWallet ${activeOptions ? 'overflowScroll' : ''}`}>
        {wallets.map((wallet, index) => {
          return (
            <div className="wallet" onClick={() => toggleWallet(wallet)} key={index}>
              <img src={`static/wallets/${wallet}.png `} alt={wallet} className="walletImg" />
              <p className="walletText">{connectorsNames[wallet]}</p>
            </div>
          );
        })}
      </div>
      {/*<p onClick={() => setActiveOptions(!activeOptions)} className={`showMoreOptionBtn ${activeOptions ? 'colorTextPink' : ''}`}>
				{text}
				</p>
				*/}
    </>
  );
}

export default Login;
