import React, { memo } from 'react';

import twitterLogo from '../../theme/images/iconFooter/twitterLogo.svg';
import telegramLogo from '../../theme/images/iconFooter/telegramLogo.svg';
import facebookLogo from '../../theme/images/iconFooter/facebookLogo.svg';
import discordLogo from '../../theme/images/iconFooter/discordLogo.svg';
import instagramLogo from '../../theme/images/iconFooter/instagramLogo.svg';

const links = [
  {
    name: 'discord',
    link: 'https://discord.gg/UJc5qgJRQx',
    icon: discordLogo
  },

  {
    name: 'telegram',
    link: 'https://t.me/tokonomo_community',
    icon: telegramLogo
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/Tokonomo_DeFi',
    icon: twitterLogo
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/tokonomo.defi',
    icon: facebookLogo
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/tokonomo.defi',
    icon: instagramLogo
  }
];

function SocialLinks() {
  return (
    <div className="innerSocial">
      {links.map(({ name, link, icon }) => {
        return (
          <a href={link} className="linkSocial" target="_blank" rel="noreferrer" key={link}>
            <img src={icon} alt={name} className="socialImage" />
          </a>
        );
      })}
    </div>
  );
}

export default memo(SocialLinks);
