import React from 'react';
import AppRoutes from './AppRoutes';
import { BSC, DAppProvider, Rinkeby, Mainnet } from '@usedapp/core';
import { ToastContainer } from 'react-toastify';

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 1);

const config = {
  readOnlyChainId: chainId,
  readOnlyUrls: {
    [BSC.chainId]: process.env.REACT_APP_BSC_RPC_URL
  },
  networks: [BSC],
  pollingInterval: 3000
};

function App() {
  return (
    <DAppProvider config={config}>
      <AppRoutes></AppRoutes>
      <ToastContainer />
    </DAppProvider>
  );
}

export default App;
