import React from 'react';
import Login from '../components/Login';

function LoginForm({ handleClose }) {
  return (
    <div className="yourWallet modal-content">
      <h1 className="yourWalletTitle">Connect your wallet</h1>
      <p className="yourWalletSupTitle">Connect with one of our available wallet providers or create a new one</p>
      <Login handleWallet={() => handleClose()} />
    </div>
  );
}

export default LoginForm;
