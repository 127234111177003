import React, { useCallback, useState } from 'react';
import Menu from '../Menu';
import close from '../../theme/images/close.svg';
import burgerMenu from '../../theme/images/burgerMenu.svg';
import MenuFooter from 'components/MenuFooter/MenuFooter';

function MobileNavigation(props) {
  const [activeMenu, setActiveMenu] = useState(false);

  const handleClose = useCallback(() => {
    setActiveMenu(false);
  }, []);

  return (
    <>
      <button className="hamburger" onClick={() => setActiveMenu(true)}>
        <img src={burgerMenu} alt="menu hamburger" />
      </button>
      <ul className={`menuHamburger ${activeMenu ? 'showMenu' : ''}`}>
        <div className="hamburgerCloseWrap">
          <button className="hamburgerClose" onClick={() => setActiveMenu(false)}>
            <img src={close} alt="close hamburger" />
          </button>
        </div>
        <Menu handleClose={handleClose} />
        <MenuFooter />
      </ul>
    </>
  );
}

export default MobileNavigation;
