import { InjectedConnector } from '@web3-react/injected-connector';
import WalletConnectProvider from '@walletconnect/web3-provider';

/* eslint-disable */

const POLLING_INTERVAL = 3000;

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);
const injected = new InjectedConnector({ supportedChainIds: [chainId] });

const walletconnect = new WalletConnectProvider({
  infuraId: process.env.REACT_APP_INFURA_ID,
  rpc: { [chainId]: process.env.REACT_APP_BSC_RPC_URL },
  qrcode: true,
  supportedChainIds: [chainId],
  pollingInterval: POLLING_INTERVAL,
  chainId: chainId
});

export const wallets = ['metamask', 'walletconnect'];

export const connectorsByName = {
  ['metamask']: injected,
  ['walletconnect']: walletconnect
};

export const connectorsNames = {
  ['metamask']: 'Metamask',
  ['walletconnect']: 'Wallet Connect'
};
