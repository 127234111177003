import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginForm from './LoginForm';
import { closeAllModals } from '../store/modals/operations';

function Modals() {
  const dispatch = useDispatch();

  const modalsActive = useSelector((st) => st.modals.active);
  const login = useSelector((st) => st.modals.login);

  const handleClose = (e) => {
    if (e.target.classList.contains('modal')) {
      dispatch(closeAllModals());
    }
  };

  const style = { display: modalsActive ? 'block' : 'none' };

  return (
    <div className="modal" onClick={handleClose} style={style}>
      {login && <LoginForm handleClose={() => dispatch(closeAllModals())} />}
    </div>
  );
}

export default memo(Modals);
