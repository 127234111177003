import { gql } from 'graphql-request';

export const GET_MY_IDOS = gql`
  query getMyIdos($account: String!) {
    idos(first: 1000, orderBy: startTime, orderDirection: desc, where: { idoOwner: $account }) {
      id
      baseToken {
        id
        name
        symbol
        decimals
      }
      saleToken {
        id
        name
        symbol
        decimals
      }
      tokenRate
      amount
      hardCap
      softCap
      liquidityPercent
      listingRate
      startTime
      endTime
      lockPeriod
      isVestingEnabled
      cliffPeriod
      idoOwner
      minSpendPerBuyer
      maxSpendPerBuyer
      lpGenerationComplete
      forceFailed
      whiteListOnly
      totalBaseCollected
      totalTokenSold
      numBuyers
      kycRequired
      verificationRequired
      kycVerified
      verified
      logoURL
      website
      facebook
      twitter
      github
      telegram
      instagram
      discord
      reddit
      description
      startTime
      endTime
    }
  }
`;

export const GET_IDOS_LIST = gql`
  query getIdos {
    idos(first: 1000, orderBy: startTime, orderDirection: desc) {
      id
      baseToken {
        id
        name
        symbol
        decimals
      }
      saleToken {
        id
        name
        symbol
        decimals
      }
      tokenRate
      amount
      hardCap
      softCap
      liquidityPercent
      listingRate
      startTime
      endTime
      lockPeriod
      isVestingEnabled
      cliffPeriod
      idoOwner
      minSpendPerBuyer
      maxSpendPerBuyer
      lpGenerationComplete
      forceFailed
      whiteListOnly
      totalBaseCollected
      totalTokenSold
      numBuyers
      kycRequired
      verificationRequired
      kycVerified
      verified
      logoURL
      website
      facebook
      twitter
      github
      telegram
      instagram
      discord
      reddit
      description
      startTime
      endTime
    }
  }
`;

export const GET_PREV_INVESTMENTS = gql`
  query getInvestments($account: String!) {
    investments(first: 1000, orderBy: id, orderDirection: desc, where: { investor: $account }) {
      ido {
        id
        baseToken {
          id
          name
          symbol
          decimals
        }
        saleToken {
          id
          name
          symbol
          decimals
        }
        tokenRate
        amount
        hardCap
        softCap
        liquidityPercent
        listingRate
        startTime
        endTime
        lockPeriod
        isVestingEnabled
        cliffPeriod
        idoOwner
        minSpendPerBuyer
        maxSpendPerBuyer
        lpGenerationComplete
        forceFailed
        whiteListOnly
        totalBaseCollected
        totalTokenSold
        numBuyers
        kycRequired
        verificationRequired
        kycVerified
        verified
        logoURL
        website
        facebook
        twitter
        github
        telegram
        instagram
        discord
        reddit
        description
        startTime
        endTime
      }

      baseDeposited
      tokensOwed
      tokensOnBalance
      tokenSwaped
      swaps
    }
  }
`;

export const GET_TOKEN_LOCKS = gql`
  query getLocks {
    tokenLocks {
      id
      token {
        id
        symbol
        decimals
      }
      amount
      initialAmount
      lockDate
      unlockTime
      owner
      lockID

      pair {
        token0 {
          id
          name
          symbol
          decimals
        }

        token1 {
          id
          name
          symbol
          decimals
        }
      }
    }
  }
`;
