export type FiltersType = {
  id: number;
  name: string;
  filter: StatusFilterEnum | SortByFilterEnum;
};

export enum StatusFilterEnum {
  all = 'all',
  upcoming = 'upcoming',
  live = 'live',
  end = 'end',
  cancelled = 'cancelled'
}

export enum SortByFilterEnum {
  noFilter = 'noFilter',
  hardCap = 'hard_cap',
  softCap = 'soft_cap',
  liqudity = 'liqudity',
  startTime = 'sale_start',
  endTime = 'sale_end'
}
