import bsc from '../theme/images/binanceCoin.svg';
import { Contract, utils } from 'ethers';
import ethereum from '../theme/images/ethereum-gold.svg';
import IDO_FACTORY_ABI from '../abi/IDOFactory.json';
import IDO_SETTINGS_ABI from '../abi/IDOSettings.json';
import IDO_REGISTRY_ABI from '../abi/IDORegistry.json';
import IDO_LOCKER_ABI from '../abi/IDOLocker.json';
import IDO_LOCK_FORWARDER_ABI from '../abi/IDOLockForwarder.json';

export type Chain = {
  name: string;
  image: any;
  shortName: string; // for url
  native: string;
  tokens: Token[];
  factory: string;
  [key: string]: any;
};

type Token = {
  title: string;
  address: string;
  decimals: number;
};

type Chains = { [n: number]: Chain };

export const chains: Chains = {
  /*1: {
    name: 'Ethereum Mainnet',
    shortName: 'eth',
    image: ethereum,
    native: 'ETH',
    tokens: [
      { title: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 },
      { title: 'WETH', address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', decimals: 18 },
      { title: 'USDT', address: '0xdAC17F958D2ee523a2206206994597C13D831ec7', decimals: 6 },
      { title: 'USDC', address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', decimals: 6 }
    ],
    factory: '0x8089fc80f278469f00fb984fa7430e06d2fa5990',
    factoryContract: new Contract('0x8089fc80f278469f00fb984fa7430e06d2fa5990', new utils.Interface(IDO_FACTORY_ABI)),
    settingsContract: new Contract('0xc2261117bf5334b6f54fc77af6be0ec3f1cceaf1', new utils.Interface(IDO_SETTINGS_ABI)),
    registryContract: new Contract('0xca1e86edcfcb8fac8b88b6efa928e4507d72a243', new utils.Interface(IDO_REGISTRY_ABI)),
    lockerContract: new Contract('0x12ae25cc9f1e38e6af5ae4671062f64e4e686687', new utils.Interface(IDO_LOCKER_ABI)),
    lockForwarderContract: new Contract(
      '0x86321f36c9775e3ae7acda55626e202a50c429e3',
      new utils.Interface(IDO_LOCK_FORWARDER_ABI)
    ),
    graph: 'https://api.thegraph.com/subgraphs/name/0xbuidlman/tokonomo',
    exchange: 'Uniswap V2',
    exchangeIcon: 'uni',
    explorer: 'https://etherscan.io',
    ipfs: 'https://api.thegraph.com/ipfs/api/v0/'
  },*/
  56: {
    name: 'BSC Mainnet',
    shortName: 'bsc',
    image: bsc,
    native: 'BNB',
    tokens: [
      { title: 'BNB', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 },
      { title: 'BUSDT', address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', decimals: 18 },
      { title: 'USDT', address: '0x55d398326f99059fF775485246999027B3197955', decimals: 18 }
    ],
    factory: '0x9e066FEE11bC78C968dD51cFC03abBd8419d8B5B',
    factoryContract: new Contract('0x9e066FEE11bC78C968dD51cFC03abBd8419d8B5B', new utils.Interface(IDO_FACTORY_ABI)),
    settingsContract: new Contract('0x92B7ebfc5F4Ccba7D2172E3b2F6Ed4a3C6Ec704b', new utils.Interface(IDO_SETTINGS_ABI)),
    registryContract: new Contract('0xe9B4D6da795fD1C8DF8B2A47699F037D6F2C5158', new utils.Interface(IDO_REGISTRY_ABI)),
    lockerContract: new Contract('0x433BBa71099169aAcCffb67a9190DaAff4c72DCe', new utils.Interface(IDO_LOCKER_ABI)),
    lockForwarderContract: new Contract(
      '0xcB639F2Ee94D76D2D68E996125278139C54715f3',
      new utils.Interface(IDO_LOCK_FORWARDER_ABI)
    ),
    graph: 'https://api.thegraph.com/subgraphs/name/0xbuidlman/tokonomo-bsc',
    exchange: 'Pancakeswap',
    exchangeIcon: 'uni',
    explorer: 'https://bscscan.com',
    ipfs: 'https://api.thegraph.com/ipfs/api/v0/'
  }
};

export default chains;
