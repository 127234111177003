import promoted1 from '../theme/images/events/promoted-1.png';
import promoted2 from '../theme/images/events/promoted-2.png';

type MockEventCard = {
  to: string; //link to event, can be external and not
  img: string; //image of event
  name: string;
};

export const promoted = {
  1: [
    {
      to: 'https://t.me/artem_ayon',
      img: promoted1,
      name: 'free listing'
    },
    {
      to: 'https://links.tokonomo.com/',
      img: promoted2,
      name: 'join our community'
    }
  ] as MockEventCard[],
  4: [
    {
      to: 'https://t.me/artem_ayon',
      img: promoted1,
      name: 'free listing'
    },
    {
      to: 'https://links.tokonomo.com/',
      img: promoted2,
      name: 'join our community'
    }
  ] as MockEventCard[],
  56: [
    {
      to: 'https://t.me/artem_ayon',
      img: promoted1,
      name: 'free listing'
    },
    {
      to: 'https://links.tokonomo.com/',
      img: promoted2,
      name: 'join our community'
    }
  ] as MockEventCard[]
};
