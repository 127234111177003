import { loadCoinsDashboardAction } from '../store/coins/actions';
import { promoted } from '../config/promoted';
import { announcements } from '../config/announcements';
import { useSelector } from 'react-redux';
import { StoreType } from 'store/store';

export const useDashboard = async (dispatch) => {
  const chain = useSelector((store: StoreType) => store.chain);
  const chainId = chain.id;

  let promoted_coins = [];
  let announcements_coins = [];

  if (promoted[chainId]) {
    promoted_coins = promoted[chainId];
  }

  if (announcements[chainId]) {
    announcements_coins = announcements[chainId];
  }

  dispatch(loadCoinsDashboardAction({ announcements_coins, promoted_coins }));
};
