import MenuFooter from 'components/MenuFooter/MenuFooter';
import React from 'react';
import Menu from '../Menu';

function DesktopNavigation() {
  return (
    <aside className="subMenu">
      <ul className="subMenuSticky">
        <Menu />
      </ul>
      <MenuFooter />
    </aside>
  );
}

export default DesktopNavigation;
