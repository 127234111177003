const initialState = {
  isLogin: false,
  user: {},
  notifications: []
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'SEND_LOGIN':
      return { ...state, ...action.payload };
    case 'LOAD_NOTIFICATIONS':
      return { ...state, notifications: action.payload };
    case 'CHECK_NOTIFICATIONS':
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};

export default user;
