const initialState = {
  active: false,
  login: false
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case 'CLOSE_ALL':
      return { ...action.payload };
    case 'ACTIVE_LOGIN':
      return { ...state, login: true, active: true };
    default:
      return state;
  }
};

export default modals;
