import SocialLinks from 'components/SocialLinks/SocialLinks';
import React from 'react';
import styles from './MenuFooter.module.scss';

const MenuFooter = () => {
  return (
    <footer className={styles.subMenuFooter}>
      <div className={styles.subMenuFooterInner}>
        <SocialLinks />
      </div>
      <p className={styles.footerText}>© 2022 TOKONOMO</p>
      <br />
      <div className={styles.wrapperLinks}>
        <a
          className={styles.footerTextUnderlineAcademy}
          href="https://academy.tokonomo.com/"
          target="_blank"
          rel="noreferrer"
        >
          Academy
        </a>
        <a className={styles.footerTextUnderline} href="http://t.me/artem_ayon" target="_blank" rel="noreferrer">
          Advertise
        </a>
      </div>
    </footer>
  );
};

export default MenuFooter;
