const initialState = {
  top_coins: [],
  announcements_coins: [],
  promoted_coins: [],
  my_idos: [],
  tokensSales: [],
  lockedTokens: [],
  investments: []
};

const coins = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_TOP_COINS':
      return { ...state, top_coins: action.payload };
    case 'LOAD_IDOS':
      return { ...state, tokensSales: action.payload };
    case 'LOAD_MY_IDOS':
      return { ...state, my_idos: action.payload };
    case 'LOAD_COINS':
      return { ...state, ...action.payload };
    case 'LOAD_LOCKED_TOKENS':
      return { ...state, lockedTokens: action.payload };
    case 'LOAD_INVESTMENTS':
      return { ...state, investments: action.payload };
    default:
      return state;
  }
};

export default coins;
