import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import MacBookDashboard from '../theme/images/MacBookDashboard.png';
import investment from '../theme/images/investment.png';
import project from '../theme/images/project.png';
import Dashboard from '../theme/images/dashboard.png';
import lineDashed from '../theme/images/lineDashed.svg';
import Diagram from '../theme/images/Diagram.png';
import KYC from '../theme/images/Kyc.png';
import RaiseFunds from '../theme/images/RaiseFunds.png';
import Locking from '../theme/images/Locking.png';
import Salute from '../theme/images/Salute.png';
import StrongBox from '../theme/images/StrongBox.png';
import Footer from '../components/Footer';
import { useSelector } from 'react-redux';
import { StoreType } from 'store/store';
import { routes } from 'routes';
import TopCoins from 'components/TopCoins';
import Partners from 'components/Partners/Partners';
import Academy from 'components/Academy/Academy';

function HomePage(props) {
  const user = useSelector((store: StoreType) => store.user);

  const actionLink = user.isLogin ? routes.dashboard : routes.auth;

  const descriptionBlock = useRef();
  return (
    <>
      <TopCoins className="marqueeWrap" />

      <section className="container">
        <div className="mainTitle">
          <h1>
            Find the next bitcoin
            <br />
            first on<span className="markerCircle">Tokonomo</span>
          </h1>
          <p className="titleDescription">
            Tokonomo is where early adopters and investors find lively, imaginative tokens before they hit mainstream
          </p>

          <Link to={actionLink} className="buttonDefault buttonGet">
            Get started
          </Link>
          <button
            className="buttonLearn"
            onClick={(e) => {
              //@ts-ignore
              descriptionBlock?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end'
              });
            }}
          >
            Learn more
          </button>
        </div>
        <div className="macImageWrap">
          <img src={MacBookDashboard} className="macImage" alt="MacBook Dashboard" />
        </div>

        <p className="contentInfo" id="landing-description">
          The space where &#128640;
          <br />
          projects meet their early investors
        </p>

        <section className="investorsWrap">
          <div className="investorsBlock">
            <img className="investorsImg" src={investment} alt="investment" />
            <p className="investorsText" ref={descriptionBlock}>
              Get early investment access
              <br />
              to the great new tokens
            </p>
            {
              <Link to={actionLink} className="buttonDefault investorButton">
                Start investing
              </Link>
            }
          </div>

          <div className="investorsBlock">
            <img className="investorsImg" src={project} alt="project" />
            <p className="investorsText">
              Launch your project with
              <br />
              IDO (Initial Dex Offering)
            </p>
            {
              <Link to={actionLink} className="buttonDefault investorButton">
                Launch project
              </Link>
            }
          </div>
        </section>
      </section>
      <section className="features container">
        <h2 className="featuresTitle">Features that make the fundraising process simple as 1-2-3</h2>
        <div className="innerFeatures">
          <div className="featuresBlock">
            <p className="featuresBlockTitle">For Investors:</p>
            <div className="innerProcessSimple featuresBlock">
              <div className="processSimpleBlock">
                <img className="processSimpleImg processSimpleImgPadding" src={Dashboard} alt="Dashboard" />
                <p className="processSimpleTitle">Portfolio Tracking</p>
                <p className="processSimpleText">Track changes in your assets from investments made</p>
                <div className="connectLine">
                  <span></span>
                  <img src={lineDashed} alt="line" />
                  <span></span>
                </div>
              </div>
              <div className="processSimpleBlock">
                <img className="processSimpleImg" src={Diagram} alt="Diagram" />
                <p className="processSimpleTitle">Automatic projects scanner</p>
                <p className="processSimpleText">
                  Estimate the risk of project's fraud based on mathematical factors before investing in
                </p>
              </div>
            </div>
          </div>
          <div className="featuresBlock">
            <p className="featuresBlockTitle">For Teams:</p>
            <div className="innerProcessSimple featuresBlock">
              <div className="processSimpleBlock">
                <img className="processSimpleImg" src={KYC} alt="KYC" />
                <p className="processSimpleTitle">KYC</p>
                <p className="processSimpleText">Optional KYC and verification for projects</p>
                <div className="connectLine">
                  <span></span>
                  <img src={lineDashed} alt="line" />
                  <span></span>
                </div>
              </div>
              <div className="processSimpleBlock">
                <img className="processSimpleImg" src={RaiseFunds} alt="RaiseFunds" />
                <p className="processSimpleTitle">Raise funds</p>
                <p className="processSimpleText">Create a token IDO with just a few clicks</p>
                <div className="connectLine">
                  <span></span>
                  <img src={lineDashed} alt="line" />
                  <span></span>
                </div>
              </div>
              <div className="processSimpleBlock">
                <img className="processSimpleImg" src={Locking} alt="" />
                <p className="processSimpleTitle">Locking</p>
                <p className="processSimpleText">
                  Lock your liqudity or your tokens to give your investors confidence in your project
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="valuablePart container">
        <div className="valuablePartTitle">
          Be a valuable part of
          <div className="valuablePartSupTitle">
            <img src={Salute} alt="Salute" className="explosionImg" />
            <p>the DeFi economy</p>
            <img src={Salute} alt="Salute" className="explosionImg" />
          </div>
        </div>
        {
          <Link to={actionLink} className="buttonDefault buttonGetFull">
            Get Started
          </Link>
        }
        <img src={StrongBox} alt="Strong Box" className="valuablePartImg" />
      </section>

      <Academy />

      <Partners />

      <Footer />
    </>
  );
}

export default HomePage;
