import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import Header from './components/Header/Header';
import Loader from './components/Loader';
import DesktopNavigation from './components/navigation/DesktopNavigation';
import TopCoins from './components/TopCoins';
import Modals from './modals';
import { privateRoutes, publicRoutes, routes } from './routes';
import { useEthers, shortenAddress } from '@usedapp/core';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useNavigate } from 'react-router';
import { sendLogin } from './store/user/operations';
import { sendLoginAction } from './store/user/actions';
import useMyIdos from './hooks/useMyIdos';
import useIdos from './hooks/useIdos';
import useInvestments from './hooks/useInvestments';
import { useLocation } from 'react-router';
import useLocks from './hooks/useLocks';
import { useDashboard } from './hooks/useDashboard';
import { StoreType } from 'store/store';
import { setChain } from 'store/chain';
import chains from 'config/chains';

const URL_SEARCH_CHAIN_KEY = 'chain';

const AppRoutes = () => {
  const { account, chainId, error } = useEthers();
  const chain = useSelector((store: StoreType) => store.chain);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  useDashboard(dispatch);
  useMyIdos(dispatch);

  // const path = chainId ? chains[chainId].graph : '';
  useIdos(dispatch);

  useInvestments(dispatch);
  useLocks(dispatch);

  const [activateError, setActivateError] = useState('');

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // only first time
    // better window.location.search, but it is not working with hash router

    const urlChain = searchParams.get(URL_SEARCH_CHAIN_KEY);

    if (urlChain) {
      const chainId = Object.keys(chains).find((key) => {
        const { shortName } = chains[key];
        return shortName === urlChain;
      });

      if (chainId) {
        // @ts-ignore
        dispatch(setChain(+chainId));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const urlChain = searchParams.get(URL_SEARCH_CHAIN_KEY);

    if (urlChain && urlChain === chain.data.shortName) {
      return;
    }

    if (
      [routes.tokensList, routes.tokenPage, routes.lockedTokens, routes.lockedToken.route].some((path) => {
        return !!matchPath(
          {
            path: path
          },
          location.pathname
        );
      })
    ) {
      setSearchParams({ chain: chain.data.shortName });
    }
  }, [chain, searchParams, location, setSearchParams]);

  useEffect(() => {
    if (error) {
      if (!error.message.includes('1013')) {
        setActivateError(error.message);

        const user = {
          wallet_address: '',
          net: {
            name: '',
            image: ''
          }
        };

        dispatch(sendLoginAction({ isLogin: '', user }));
      } else {
        setActivateError('');
      }
    }

    if (account && !activateError) {
      // @ts-ignore
      dispatch(sendLogin(shortenAddress(account), chainId, navigate, location));
    }

    if (!error) {
      setActivateError('');
    }

    if (!account && !error) {
      const user = {
        wallet_address: '',
        net: {
          name: '',
          image: ''
        }
      };
      dispatch(sendLoginAction({ isLogin: '', user }));

      setActivateError('');
    }
    /* eslint-disable-next-line */
  }, [error, account, chainId]);

  return (
    <Fragment>
      <Header></Header>

      <Suspense fallback={<Loader />}>
        <Routes>
          {publicRoutes.map((params) => {
            return <Route {...params} key={params.path} />;
          })}

          {privateRoutes.map((params) => {
            return (
              <Route
                {...params}
                key={params.path}
                element={
                  <div className="container containerDashboard">
                    <DesktopNavigation />
                    <div className="innerPageBlock">
                      <TopCoins className="marqueeWrap disableMargin" />

                      <section className="containerMainCard">{params.element}</section>
                    </div>
                  </div>
                }
              />
            );
          })}

          <Route path="*" element={<p>Not found page</p>} />
        </Routes>
      </Suspense>

      <Modals />
    </Fragment>
  );
};

export default AppRoutes;
