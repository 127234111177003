export const loadTopCoinsAction = (payload) => ({
  type: 'LOAD_TOP_COINS',
  payload
});

export const loadCoinsDashboardAction = (payload) => ({
  type: 'LOAD_COINS',
  payload
});

export const loadMyIdosAction = (payload) => ({
  type: 'LOAD_MY_IDOS',
  payload
});

export const loadIdosAction = (payload) => ({
  type: 'LOAD_IDOS',
  payload
});

export const loadLockedTokensAction = (payload) => ({
  type: 'LOAD_LOCKED_TOKENS',
  payload
});

export const loadInvestmentsAction = (payload) => ({
  type: 'LOAD_INVESTMENTS',
  payload
});
