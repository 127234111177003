import { request, gql } from 'graphql-request';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { GET_PAIRS, GET_TOKEN_LOCKS } from '../queries';
import chains from '../config/chains';
import { useEffect, useState } from 'react';
import cardIcon from '../theme/images/cardIcon.png';
import { loadLockedTokensAction } from '../store/coins/actions';
import { loadLockedTokens } from '../store/coins/operations';

export const useLocks = async (dispatch) => {
  const { chainId } = useEthers();

  const fetchData = async () => {
    try {
      const data = await request(chainId ? chains[chainId].graph : '', GET_TOKEN_LOCKS);

      let locks = [];

      if (data.tokenLocks.length > 0 && chainId) {
        data.tokenLocks.forEach(async (value, i) => {
          if (value.pair) {
            locks.push({
              id: value.id,
              name: value.pair.token0.symbol + '_' + value.pair.token1.symbol + ' LP',
              icon: require(`../theme/images/cryptoCoin/${chains[chainId].exchangeIcon}.png`),
              amount: utils.formatUnits(value.amount, value.token.decimals),
              initialAmount: utils.formatUnits(value.initialAmount, value.token.decimals),
              isUsersIdo: false,
              lockTime: value.lockDate,
              unlockTime: value.unlockTime,
              address: value.token.id,
              lockID: value.lockID,
              owner: value.owner
            });
          }
        });
      }


    dispatch(loadLockedTokensAction(locks));
    } catch (e) {


      dispatch(loadLockedTokensAction([]));
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, chainId]);
};

export default useLocks;
