import React from 'react';
import { lazy } from 'react';
import AuthPage from '../pages/AuthPage';
import HomePage from '../pages/HomePage';

export const routes = {
  account: '/account',
  dashboard: '/dashboard',
  auth: '/auth',
  home: '/',
  lockedToken: {
    route: '/lockedToken/:tokenId',
    getUrl(address) {
      return this.route.replace(':tokenId', address);
    }
  },
  tokenPage: '/ido/:tokenId',
  tokensList: '/tokensList',
  editIdoToken: '/manageIDO/:tokenId',
  manageIdoToken: '/manageIDO',
  launchIDO: '/launchIDO',
  createLock: '/createLock',
  lockedTokens: '/lockedTokens',
  previousInvestments: '/previousInvestments',
  kycVerification: '/account/KYC-verification'
};

export const publicRoutes = [
  { path: routes.home, exact: true, element: <HomePage /> },
  { path: routes.auth, exact: true, element: <AuthPage /> }
];

const DashboardPage = lazy(() => import('../pages/KYCPage/DashboardPage/DashboardPage'));
const TokensListPage = lazy(() => import('../pages/TokensListPage/TokensListPage'));
const TokenPage = lazy(() => import('../pages/TokenPage/TokenPage'));
const EditTokenPage = lazy(() => import('../pages/EditTokenPage'));
const ManageIdoPage = lazy(() => import('../pages/ManageIdoPage'));
const LaunchIdoPage = lazy(() => import('../pages/LaunchIdoPage'));
const CreateLockPage = lazy(() => import('../pages/CreateLockPage'));
const LockedTokensPage = lazy(() => import('../pages/LockedTokensPage'));
const LockedTokenPage = lazy(() => import('../pages/LockedTokenPage/LockedTokenPage'));
const AccountPage = lazy(() => import('../pages/AccountPage'));
const PreviousInvestmentsPage = lazy(() => import('../pages/PreviousInvestmentsPage'));
const KYCPage = lazy(() => import('../pages/KYCPage/KYCPage'));

export const privateRoutes = [
  { path: routes.dashboard, exact: true, element: <DashboardPage /> },
  { path: routes.tokensList, exact: true, element: <TokensListPage /> },
  { path: routes.tokenPage, exact: true, element: <TokenPage /> },
  { path: routes.lockedToken.route, exact: true, element: <LockedTokenPage /> },
  { path: routes.editIdoToken, exact: true, element: <EditTokenPage /> },
  { path: routes.manageIdoToken, exact: true, element: <ManageIdoPage /> },
  { path: routes.launchIDO, exact: true, element: <LaunchIdoPage /> },
  { path: routes.createLock, exact: true, element: <CreateLockPage /> },
  { path: routes.lockedTokens, exact: true, element: <LockedTokensPage /> },
  { path: routes.account, exact: true, element: <AccountPage /> },
  { path: routes.previousInvestments, exact: true, element: <PreviousInvestmentsPage /> },
  { path: routes.kycVerification, exact: true, element: <KYCPage /> }
];
