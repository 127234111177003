import announce1 from '../theme/images/events/announce-1.png';

type MockEventCard = {
  to: string; //link to event, can be external and not
  img: string; //image of event
  name: string;
};

export const announcements = {
  1: [
    {
      to: '',
      img: announce1,
      name: 'public beta'
    }
  ] as MockEventCard[],
  4: [
    {
      to: '',
      img: announce1,
      name: 'public beta'
    }
  ] as MockEventCard[],
  56: [
    {
      to: '',
      img: announce1,
      name: 'public beta'
    }
  ] as MockEventCard[]
};
