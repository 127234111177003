export const sendLoginAction = (payload) => ({
  type: 'SEND_LOGIN',
  payload
});

export const loadNotificationsAction = (payload) => ({
  type: 'LOAD_NOTIFICATIONS',
  payload
});

export const checkNotificationsAction = (payload) => ({
  type: 'CHECK_NOTIFICATIONS',
  payload
});
