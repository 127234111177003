import { sendLoginAction, loadNotificationsAction, checkNotificationsAction } from './actions';
import dollarSVG from '../../theme/images/dollar.svg';
import chains from '../../config/chains';
import { setChain } from '../chain';

export const sendLogin = (title, chainId, navigate, location) => (dispatch) => {
  if (!chainId) {
    const user = {
      wallet_address: '',
      net: {
        name: '',
        image: ''
      }
    };

    dispatch(sendLoginAction({ isLogin: '', user }));
  } else {
    const user = {
      wallet_address: title,
      net: {
        name: chains[chainId].name,
        image: chains[chainId].image
      }
    };
    dispatch(loadNotifications());
    dispatch(sendLoginAction({ isLogin: title, user }));

    if (chainId) {
      dispatch(setChain(chainId));
      if (location) {
        navigate(location);
      } else {
        navigate('/dashboard');
      }
    } else {
      navigate('/auth');
    }
  }
};

export const loadNotifications = (title, navigate) => (dispatch) => {
  const notification = [
    {
      id: 1,
      title: 'Previous investments',
      description: 'View your previous investments ROI',
      icon: dollarSVG,
      href: '/previousInvestments'
    }
  ];

  dispatch(loadNotificationsAction(notification));
};

export const checkNotification = (id) => (dispatch, useState) => {
  const notifications = useState().user.notifications;

  const newNotification = notifications.filter((el) => el.id !== id);

  dispatch(checkNotificationsAction(newNotification));
};
