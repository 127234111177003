import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { routes } from 'routes';
import { StoreType } from 'store/store';
import Footer from '../components/Footer';
import Login from '../components/Login';

function AuthPage(props) {
  const user = useSelector(({ user }: StoreType) => user);
  if (user.isLogin) {
    return <Navigate to={routes.dashboard} />;
  }

  return (
    <>
      <section className="innerRegisterPage">
        <div className="yourWallet yourWalletPage">
          <h1 className="yourWalletTitle">Connect your wallet</h1>
          <p className="yourWalletSupTitle">Connect with one of our available wallet providers or create a new one</p>
          {/* @ts-ignore */}
          <Login />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AuthPage;
