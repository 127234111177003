import { combineReducers } from 'redux';
import user from './user/reducer';
import coins from './coins/reducer';
import modals from './modals/reducer';
import { chainReducer } from './chain';

export default combineReducers({
  modals,
  user,
  coins,
  chain: chainReducer
});
