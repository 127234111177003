import { request, gql } from 'graphql-request';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { GET_PREV_INVESTMENTS } from '../queries';
import chains from '../config/chains';
import { useEffect, useState } from 'react';
import cardIcon from '../theme/images/cardIcon.png';
import { loadInvestmentsAction } from '../store/coins/actions';
import { useBlockNumber } from '@usedapp/core';
import { fCRCurrency } from '../utils/formatNumber';

const statuses = [
  {
    status: 'end',
    class: 'backLightEnded',
    title: 'Sale Ended'
  },
  {
    status: 'upcoming',
    class: 'backLightUpcoming',
    title: 'Upcoming'
  },
  {
    status: 'live',
    class: 'backLightLive',
    title: 'Sale Live'
  },
  {
    status: 'cancelled',
    class: 'backLightEnded',
    title: 'Cancelled'
  }
];

export const useInvestments = async (dispatch) => {
  const { chainId, account } = useEthers();

  const [idos, setIdos] = useState([]);

  const fetchData = async () => {
    const variables = {
      account: account ? account.toLowerCase() : ''
    };

    try {
      const data = await request(chainId ? chains[chainId].graph : '', GET_PREV_INVESTMENTS, variables);

      const result = [];
      if (data.investments.length > 0 && chainId) {
        data.investments.forEach((value) => {
          const base = value.ido.baseToken.name == 'unknown' ? chains[chainId].native : value.ido.baseToken.symbol;

          result.push({
            id: value.ido.id,
            title: value.ido.saleToken.name,
            base: base,
            symbol: value.ido.saleToken.symbol,
            decimals: value.ido.saleToken.decimals,
            baseToken: value.ido.baseToken.id,
            base_decimals: value.ido.baseToken.decimals,
            saleToken: value.ido.saleToken.id,
            icon: cardIcon,
            totalBaseCollected: utils.formatUnits(value.ido.totalBaseCollected, value.ido.baseToken.decimals),
            pRate: utils.formatEther(value.ido.tokenRate),
            lRate: utils.formatEther(value.ido.listingRate),
            toggle:
              '1 ' +
              base +
              ' = ' +
              fCRCurrency(utils.formatEther(value.ido.tokenRate)) +
              ' ' +
              value.ido.saleToken.symbol,
            listToggle:
              '1 ' +
              base +
              ' = ' +
              fCRCurrency(utils.formatEther(value.ido.listingRate)) +
              ' ' +
              value.ido.saleToken.symbol,
            soft_cap: utils.formatUnits(value.ido.softCap, value.ido.baseToken.decimals),
            hard_cap: utils.formatUnits(value.ido.hardCap, value.ido.baseToken.decimals),
            soft:
              ' ' +
              utils.formatUnits(value.ido.softCap, value.ido.baseToken.decimals) +
              ' ' +
              base +
              ' / ' +
              fCRCurrency(utils.formatUnits(value.ido.hardCap, value.ido.baseToken.decimals)) +
              ' ' +
              base, //'1 BNB - 2 BNB',
            start_price: utils.formatUnits(value.ido.totalBaseCollected, value.ido.baseToken.decimals),
            status: value.ido.forceFailed
              ? statuses[3]
              : value.ido.startTime * 1000 <= new Date().getTime() && value.ido.endTime * 1000 > new Date().getTime()
              ? statuses[2]
              : value.ido.endTime * 1000 > new Date().getTime()
              ? statuses[1]
              : statuses[0],
            status_flag: value.ido.forceFailed
              ? 3
              : value.ido.startTime * 1000 <= new Date().getTime() && value.ido.endTime * 1000 > new Date().getTime()
              ? 2
              : value.ido.endTime * 1000 > new Date().getTime()
              ? 1
              : 0,
            forceFailed: value.ido.forceFailed,
            end_price: utils.formatUnits(value.ido.hardCap, value.ido.baseToken.decimals),
            isUsersIdo: false,
            featured: true,
            inStarred: false,
            isLocked: value.ido.lpGenerationComplete,
            isKYS: value.ido.kycVerified,
            isChecked: value.ido.verified,
            isWhitelist: value.ido.whiteListOnly,
            percent: ((value.ido.totalBaseCollected / value.ido.hardCap) * 100).toFixed(2),
            liqudity: value.ido.liquidityPercent / 100,
            lockup_time: Math.floor(value.ido.lockPeriod / 3600) + ' days',
            sale_start: value.ido.startTime * 1000,
            sale_end: value.ido.endTime * 1000,
            min_price: utils.formatUnits(value.ido.minSpendPerBuyer, value.ido.baseToken.decimals),
            max_price: utils.formatUnits(value.ido.maxSpendPerBuyer, value.ido.baseToken.decimals),
            exchange: chains[chainId].exchange,
            logo_url: value.ido.logoURL,
            website: value.ido.website,
            facebook: value.ido.facebook,
            twitter: value.ido.twitter,
            github: value.ido.github,
            telegram: value.ido.telegram,
            instagram: value.ido.instagram,
            discord: value.ido.discord,
            reddit: value.ido.reddit,
            owner: value.ido.idoOwner,

            investments_attributes: {
              my_invest: utils.formatUnits(value.baseDeposited, value.ido.baseToken.decimals) + ' ' + base
            }
          });
        });
      }

      dispatch(loadInvestmentsAction(result));
    } catch {
      dispatch(loadInvestmentsAction([]));
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, chainId,account]);
};

export default useInvestments;
