import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import Logo from '../theme/images/Logo.svg';
import SocialLinks from './SocialLinks/SocialLinks';

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="innerFooter">
          <Link to={routes.home}>
            <img src={Logo} alt="Logo" className="footerTitleLogo" />
          </Link>
          <SocialLinks />
        </div>

        <p className="footerText">
          Disclaimer: The information provided shall not in any way constitute a recommendation as to whether you should
          invest in any product discussed. We accept no liability for any loss occasioned to any person acting or
          refraining from action as a result of any material provided or published. © 2022
        </p>
      </div>
    </footer>
  );
}

export default Footer;
