import { request, gql } from 'graphql-request';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { GET_MY_IDOS } from '../queries';
import chains from '../config/chains';
import { useEffect, useState } from 'react';
import cardIcon from '../theme/images/cardIcon.png';
import { loadMyIdosAction } from '../store/coins/actions';
import { useBlockNumber } from '@usedapp/core';
import { fCRCurrency } from '../utils/formatNumber';
import { chainStateReducer } from '@usedapp/core/dist/esm/src/providers';

const statuses = [
  {
    status: 'end',
    class: 'backLightEnded',
    title: 'Sale Ended'
  },
  {
    status: 'upcoming',
    class: 'backLightUpcoming',
    title: 'Upcoming'
  },
  {
    status: 'live',
    class: 'backLightLive',
    title: 'Sale Live'
  },
  {
    status: 'cancelled',
    class: 'backLightEnded',
    title: 'Cancelled'
  }
];

export const useMyIdos = async (dispatch) => {
  const { chainId, account } = useEthers();


  const fetchData = async() => {
  let idos = [];

  const variables = {
    account: account ? account.toLowerCase() : ''
  };

  try {
  const data = await request(chainId ? chains[chainId].graph : '', GET_MY_IDOS, variables);

  if (data.idos.length > 0 && chainId) {
    data.idos.forEach((value) => {
      const base = value.baseToken.name == 'unknown' ? chains[chainId].native : value.baseToken.symbol;

      idos.push({
        id: value.id,
        title: value.saleToken.name,
        base: base,
        symbol: value.saleToken.symbol,
        decimals: value.saleToken.decimals,
        baseToken: value.baseToken.id,
        base_decimals: value.baseToken.decimals,
        saleToken: value.saleToken.id,
        icon: cardIcon,
        totalBaseCollected: utils.formatUnits(value.totalBaseCollected, value.baseToken.decimals),
        pRate: utils.formatEther(value.tokenRate),
        lRate: utils.formatEther(value.listingRate),
        toggle: '1 ' + base + ' = ' + fCRCurrency(utils.formatEther(value.tokenRate)) + ' ' + value.saleToken.symbol,
        listToggle:
          '1 ' + base + ' = ' + fCRCurrency(utils.formatEther(value.listingRate)) + ' ' + value.saleToken.symbol,
        soft_cap: utils.formatUnits(value.softCap, value.baseToken.decimals),
        hard_cap: utils.formatUnits(value.hardCap, value.baseToken.decimals),
        soft:
          ' ' +
          utils.formatUnits(value.softCap, value.baseToken.decimals) +
          ' ' +
          base +
          ' / ' +
          fCRCurrency(utils.formatUnits(value.hardCap, value.baseToken.decimals)) +
          ' ' +
          base, //'1 BNB - 2 BNB',
        start_price: utils.formatUnits(value.totalBaseCollected, value.baseToken.decimals),
        status: value.forceFailed
          ? statuses[3]
          : value.startTime * 1000 <= new Date().getTime() && value.endTime * 1000 > new Date().getTime()
          ? statuses[2]
          : value.endTime * 1000 > new Date().getTime()
          ? statuses[1]
          : statuses[0],
        status_flag: value.forceFailed
          ? 3
          : value.startTime * 1000 <= new Date().getTime() && value.endTime * 1000 > new Date().getTime()
          ? 2
          : value.endTime * 1000 > new Date().getTime()
          ? 1
          : 0,
        forceFailed: value.forceFailed,
        end_price: utils.formatUnits(value.hardCap, value.baseToken.decimals),
        isUsersIdo: true,
        featured: true,
        inStarred: false,
        isLocked: value.lpGenerationComplete,
        isKYS: value.kycVerified,
        isChecked: value.verified,
        isWhitelist: value.whiteListOnly,
        percent: ((value.totalBaseCollected / value.hardCap) * 100).toFixed(2),
        liqudity: value.liquidityPercent / 100,
        lockup_time: Math.floor(value.lockPeriod / 3600) + ' days',
        sale_start: value.startTime * 1000,
        sale_end: value.endTime * 1000,
        min_price: utils.formatUnits(value.minSpendPerBuyer, value.baseToken.decimals),
        max_price: utils.formatUnits(value.maxSpendPerBuyer, value.baseToken.decimals),
        exchange: chains[chainId].exchange,
        logo_url: value.logoURL,
        website: value.website,
        facebook: value.facebook,
        twitter: value.twitter,
        github: value.github,
        telegram: value.telegram,
        instagram: value.instagram,
        discord: value.discord,
        reddit: value.reddit,
        owner: value.idoOwner
      });
    });
  }
  dispatch(loadMyIdosAction(idos));

  } catch {
    dispatch(loadMyIdosAction([]));
  }
  }

  useEffect(() => {
    fetchData();
  }, [dispatch, chainId, account]);
};

export default useMyIdos;
