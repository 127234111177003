import { applyMiddleware, compose, createStore } from 'redux';
// @ts-ignore
import reducer from './reducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { StoreChain } from './chain';



export type StoreType = {
  user: {
    isLogin: boolean;
    wallet_address: string;
    user: any;
  };
  chain: StoreChain;
  coins: any;
};

const store = createStore(reducer, composeWithDevTools(compose(applyMiddleware(thunk))));

export default store;
