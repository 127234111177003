import { Web3Ethers } from '@usedapp/core';
import chains, { Chain } from 'config/chains';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export type StoreChain = {
  id?: number;
  data: undefined | Chain;
};

const LS_KEY = 'chainId';

const defaultChainId = +localStorage.getItem(LS_KEY) || 4;
localStorage.setItem(LS_KEY, String(defaultChainId));

const initialState: StoreChain = {
  id: defaultChainId,
  data: chains[defaultChainId]
};

export enum ChainTypes {
  SET_CHAIN = 'SET_CHAIN'
}

const setChain = (id: number, switchNetwork?: Web3Ethers['switchNetwork']) => async (dispatch: any) => {
  if (switchNetwork) {
    try {
      await switchNetwork(id);
    } catch (error) {
      return;
    }
  }

  // toast.success('Network changed');
  dispatch({
    type: ChainTypes.SET_CHAIN,
    payload: {
      id
    }
  });
};

const chainReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ChainTypes.SET_CHAIN:
      const { id } = action.payload;

      localStorage.setItem(LS_KEY, id);

      const data = chains[id];
      return { id: action.payload.id, data };
    default:
      return state;
  }
};

export { chainReducer, setChain };
