import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'routes';
import { activateLogin } from 'store/modals/operations';
import { StoreType } from 'store/store';

interface Props {
  handleClose?: () => void;
}

function Menu({ handleClose }: Props) {
  const launchpadList = [
    { href: '/launchIDO', name: 'Launch IDO', classIcon: 'iconLaunchIDO' },
    { href: '/manageIDO', name: 'Manage IDO', classIcon: 'iconManageIDO', requireLogin: true },
    { href: '/tokensList', name: 'Tokens List', classIcon: 'iconTokensList' }
  ];

  const dispatch = useDispatch();

  const lockerList = [
    // { href: '/createLock', name: 'Create Lock', classIcon: 'iconCreateLock' },
    //{href: '/lockedTokens', name: 'Locked Tokens', classIcon: 'iconLockedTokens'},
    { href: '/lockedTokens', name: 'Locked LPs', classIcon: 'iconLockedLPs' }
  ];

  const user = useSelector((st: StoreType) => st.user);

  const menuListArray = [
    { href: '/dashboard', name: 'Dashboard', classIcon: 'iconDashboard' },
    { href: '#', name: 'Launchpad', classIcon: 'iconLaunchpad', list: launchpadList },
    { href: '#', name: 'Locker', classIcon: 'iconLocker', list: lockerList },
    { href: routes.account, name: 'Your Account', classIcon: 'iconLaunchpad', notification: true },
    { href: 'https://docs.tokonomo.com', name: 'Docs', classIcon: 'iconDocs' }
  ];

  const [menuList, setMenuList] = useState(menuListArray);

  const location = useLocation();

  useEffect(() => {
    if (!!handleClose) {
      handleClose();
    }
  }, [location.pathname, handleClose]);

  const handleActiveSubMenu = (index) => {
    const newMenuList = menuList;

    if (!!newMenuList[index].list) {
      // @ts-ignore
      newMenuList[index].active = !newMenuList[index].active;
      setMenuList([...newMenuList]);
    }
  };

  const generateMenu = (listMenu) => {
    return listMenu.map((item, index) => {
      let classes = !!item.list ? 'menuItemAccordion' : 'menuItem';
      classes += !!item.list && item.active ? ' openAccordion' : '';
      classes += !!matchPath(item.href, location.pathname) ? ' menuItemActive' : '';

      if (item.requireLogin && !user.isLogin) {
        return null;
      }

      return (
        <li key={index}>
          <Link
            to={item.href}
            onClick={(e) => {
              if (item.name === 'Docs') {
                window.open(item.href);
                return e.preventDefault();
              }

              if (item.href === routes.account && !user.isLogin) {
                e.preventDefault();
                // @ts-ignore
                dispatch(activateLogin());

                return;
              }

              if (!!item.list) {
                e.preventDefault();
                handleActiveSubMenu(index);
              }
            }}
            className={classes}
          >
            {!!item.classIcon && <div className={`menuItemIcon ${item.classIcon}`}></div>}
            {item.name}
          </Link>
          {!!item.list && <ul className="menuAccordion">{generateMenu(item.list)}</ul>}
        </li>
      );
    });
  };

  return <>{generateMenu(menuList)}</>;
}

export default memo(Menu);
