import { request } from 'graphql-request';
import { useEthers } from '@usedapp/core';
import { utils } from 'ethers';
import { GET_IDOS_LIST } from '../queries';
import chains from '../config/chains';
import { useCallback, useEffect } from 'react';
import cardIcon from '../theme/images/cardIcon.png';
import { loadIdosAction } from '../store/coins/actions';
import { fCRCurrency } from '../utils/formatNumber';
import { featured } from '../config/featured';
import { useSelector } from 'react-redux';
import { StoreType } from 'store/store';
import { toast } from 'react-toastify';

const statuses = [
  {
    status: 'end',
    class: 'backLightEnded',
    title: 'Sale Ended'
  },
  {
    status: 'upcoming',
    class: 'backLightUpcoming',
    title: 'Upcoming'
  },
  {
    status: 'live',
    class: 'backLightLive',
    title: 'Sale Live'
  },
  {
    status: 'cancelled',
    class: 'backLightEnded',
    title: 'Cancelled'
  }
];

export const useIdos = (dispatch) => {
  const { account } = useEthers();
  const chain = useSelector((store: StoreType) => store.chain);
  const chainId = chain.id;

  const fetchData = useCallback(async () => {
    try {
      const data = await request(chainId ? chains[chainId].graph : '', GET_IDOS_LIST);

      const result = [];
      if (data.idos.length > 0 && chainId) {
        data.idos.forEach((value) => {
          const base = value.baseToken.name === 'unknown' ? chains[chainId].native : value.baseToken.symbol;

          result.push({
            id: value.id,
            title: value.saleToken.name,
            base: base,
            symbol: value.saleToken.symbol,
            decimals: value.saleToken.decimals,
            baseToken: value.baseToken.id,
            base_decimals: value.baseToken.decimals,
            saleToken: value.saleToken.id,
            icon: cardIcon,
            totalBaseCollected: utils.formatUnits(value.totalBaseCollected, value.baseToken.decimals),
            pRate: utils.formatEther(value.tokenRate),
            lRate: utils.formatEther(value.listingRate),
            toggle:
              '1 ' + base + ' = ' + fCRCurrency(utils.formatEther(value.tokenRate)) + ' ' + value.saleToken.symbol,
            listToggle:
              '1 ' + base + ' = ' + fCRCurrency(utils.formatEther(value.listingRate)) + ' ' + value.saleToken.symbol,
            soft_cap: utils.formatUnits(value.softCap, value.baseToken.decimals),
            hard_cap: utils.formatUnits(value.hardCap, value.baseToken.decimals),
            soft:
              ' ' +
              utils.formatUnits(value.softCap, value.baseToken.decimals) +
              ' ' +
              base +
              ' / ' +
              fCRCurrency(utils.formatUnits(value.hardCap, value.baseToken.decimals)) +
              ' ' +
              base, //'1 BNB - 2 BNB',
            start_price: utils.formatUnits(value.totalBaseCollected, value.baseToken.decimals),
            status: value.forceFailed
              ? statuses[3]
              : value.startTime * 1000 <= new Date().getTime() && value.endTime * 1000 > new Date().getTime()
              ? statuses[2]
              : value.endTime * 1000 > new Date().getTime()
              ? statuses[1]
              : statuses[0],
            status_flag: value.forceFailed
              ? 3
              : value.startTime * 1000 <= new Date().getTime() && value.endTime * 1000 > new Date().getTime()
              ? 2
              : value.endTime * 1000 > new Date().getTime()
              ? 1
              : 0,
            forceFailed: value.forceFailed,
            end_price: utils.formatUnits(value.hardCap, value.baseToken.decimals),
            isUsersIdo: false,
            featured: !!featured[chainId]?.[value.id],
            inStarred: false,
            isLocked: value.lpGenerationComplete,
            isKYS: value.kycVerified,
            isChecked: value.verified,
            isWhitelist: value.whiteListOnly,
            percent: ((value.totalBaseCollected / value.hardCap) * 100).toFixed(2),
            liqudity: value.liquidityPercent / 100,
            lockup_time: Math.floor(value.lockPeriod / 3600) + ' days',
            sale_start: value.startTime * 1000,
            sale_end: value.endTime * 1000,
            min_price: utils.formatUnits(value.minSpendPerBuyer, value.baseToken.decimals),
            max_price: utils.formatUnits(value.maxSpendPerBuyer, value.baseToken.decimals),
            exchange: chains[chainId].exchange,
            logo_url: value.logoURL,
            website: value.website,
            facebook: value.facebook,
            twitter: value.twitter,
            github: value.github,
            telegram: value.telegram,
            instagram: value.instagram,
            discord: value.discord,
            reddit: value.reddit,
            description: value.description,
            owner: value.idoOwner
          });
        });
      }
      dispatch(loadIdosAction(result));
    } catch (err) {
      console.dir(err);
      // temp
      //toast.error(JSON.stringify(err));
      dispatch(loadIdosAction([]));
    }
  }, [chainId, dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, chainId, account, fetchData]);
};

export default useIdos;
