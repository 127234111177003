import React from 'react';
import styles from './Academy.module.scss';
import cn from 'classnames';
import AcademyPhoto from './images/tokonomo-academy-person.png';

const Academy = () => {
  return (
    <section className={cn(styles.wrapperAcademy, 'container')}>
      <h2 className="partnersTitle">Discover our one-stop guide to DeFi</h2>

      <a className="buttonDefault buttonGetFull" href="https://academy.tokonomo.com/" target="_blank" rel="noreferrer">
        Tokonomo Academy
      </a>

      <img src={AcademyPhoto} alt="Tokonomo Academy" className="valuablePartImg" />
    </section>
  );
};

export default Academy;
