import React from 'react';
import styles from './ChainsSelect.module.scss';

import { chains, Chain } from 'config/chains';
import { useDispatch, useSelector } from 'react-redux';
import { setChain } from 'store/chain';
import { useEthers } from '@usedapp/core';
import { StoreType } from 'store/store';

function ChainsSelect() {
  const dispatch = useDispatch();
  const { switchNetwork } = useEthers();
  const user = useSelector((store: StoreType) => store.user);

  return (
    <ul className={styles.chains}>
      {Object.keys(chains).map((chainId) => {
        const { name, image } = chains[+chainId] as Chain;

        return (
          <li className={styles.chain} key={name}>
            <button
              onClick={() => {
                // @ts-ignore
                dispatch(setChain(+chainId, user.isLogin ? switchNetwork : undefined));
              }}
            >
              <img src={image} alt={name} />
              <span>{name}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default ChainsSelect;
