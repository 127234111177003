/* eslint-disable */
// TODO: remove
import {
  loadTopCoinsAction,
  loadCoinsDashboardAction,
  loadMyIdosAction,
  loadLockedTokensAction,
  loadInvestmentsAction
} from './actions';
import faker from '@faker-js/faker';
import cardIcon from '../../theme/images/cardIcon.png';
import useMyIdos from '../../hooks/useMyIdos';
import { useBlockNumber } from '@usedapp/core';
import { fCRCurrency } from '../../utils/formatNumber';
import { useState } from 'react';
import { SortByFilterEnum, StatusFilterEnum } from 'types/statusFilterType';
const statuses = [
  {
    status: 'end',
    class: 'backLightEnded',
    title: 'Sale Ended'
  },
  {
    status: 'upcoming',
    class: 'backLightUpcoming',
    title: 'Upcoming'
  },
  {
    status: 'live',
    class: 'backLightLive',
    title: 'Sale Live'
  }
];

const tokens = ['filecoin', 'uni', 'xrp', 'bananna', 'luna'];

export const loadTopCoins = () => (dispatch) => {
  const postCoins = [
    /*{
            id: 1,
			name: 'KBOX'
		},
		{
            id: 2,
			name: 'UNI'
		},
		{
            id: 3,
			name: 'CRTP'
		},
        {
            id: 4,
			name: 'BRC'
		},
		{
            id: 5,
			name: 'FNX'
		},
		{
            id: 6,
			name: 'YTX'
		},
        {
            id: 7,
			name: 'YTX'
		},
        {
            id: 8,
			name: 'BRC'
		},
		{
            id: 9,
			name: 'CRPT'
		},
		{
            id: 10,
			name: 'CRYPTO'
		},
        {
            id: 11,
			name: 'INU'
		}
		*/
  ];

  dispatch(loadTopCoinsAction(postCoins));
};

export const loadCoinsDashboard = () => (dispatch) => {
  const announcements_coins = [];
  const promoted_coins = [];

  for (let i = 0; i < 11; i++) {
    announcements_coins.push({
      event: 'LISTING',
      date: '03.10.21',
      coin: faker.finance.currencyCode() + 'T'
    });
    promoted_coins.push({
      event: 'LISTING',
      date: '03.10.21',
      coin: faker.finance.currencyCode() + 'T'
    });
  }

  dispatch(loadCoinsDashboardAction({ announcements_coins, promoted_coins }));
};

export const loadMyIdos = () => (dispatch, useState) => {
  const idos_list = useState().coins.my_idos;

  if (!idos_list.length) {
    const list = [];
    for (let i = 0; i < 11; i++) {
      list.push({
        id: i + 1,
        title: faker.random.words(2),
        icon: cardIcon,
        toggle: '1 BNB = 1 GODS',
        soft: '1 BNB - 2 BNB',
        start_price: 1,
        status: statuses[faker.mersenne.rand(0, 2)],
        end_price: 200,
        isUsersIdo: true,
        featured: faker.datatype.boolean(),
        isChecked: faker.datatype.boolean(),
        inStarred: faker.datatype.boolean(),
        isLocked: faker.datatype.boolean(),
        isKYS: faker.datatype.boolean(),
        percent: faker.mersenne.rand(0, 99),
        liqudity: faker.mersenne.rand(51, 90),
        lockup_time: '400 000 minutes',
        sale_start: faker.date.soon(10)
      });
    }
    dispatch(loadMyIdosAction(list));
  }
};

export const filterCoins = (filterOnly, search, data, status, sortBy) => {
  let filterIdos = structuredClone(data);

  if (filterOnly === 'featured') {
    filterIdos = filterIdos.filter((item) => item[filterOnly]);
  }
  if (filterOnly === 'favorite') {
    const favoriteTokens = JSON.parse(localStorage.getItem('favorite'));

    if (favoriteTokens) {
      filterIdos = filterIdos.filter((item) => favoriteTokens.find((token) => token === item.id));
    }
  }
  if (!!search) {
    filterIdos = filterIdos.filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));
  }
  if (!!status && status !== StatusFilterEnum.all) {
    filterIdos = filterIdos.filter((ido) => ido.status.status === status);
  }

  if (!!sortBy && sortBy !== SortByFilterEnum.noFilter) {
    filterIdos = filterIdos.sort((a, b) => Number(b[sortBy]) - Number(a[sortBy]));
  }

  return filterIdos;
};

export const getLockedLpInfo = (tokenId) => (dispatch, useState) => {
  const lockedTokens = useState().coins.lockedTokens;

  const token = lockedTokens.find((item) => item.address === tokenId);

  return token;
};

export const getTokenInfo = (tokenId) => (dispatch, useState) => {
  const tokensSales = useState().coins.tokensSales;

  const token = tokensSales.find((item) => +item.id === +tokenId);

  if (token) {
    token['sale_type'] = token.isWhitelist ? 'Whitelist' : 'Public';
    token['presale_address'] = token.id;
    token['token_address'] = token.saleToken;
    token['name'] = token.title;
    token['token_for_presale'] = fCRCurrency(token.pRate * token.hard_cap) + ' ' + token.symbol;
    token['token_for_liqudity'] = fCRCurrency(token.lRate * token.hard_cap) + ' ' + token.symbol;
    token['presale_rate'] = token.toggle;
    token['listing_rate'] = token.listToggle;
    token['market_cap'] = '$ 482,328,003,894';
    //token['min_price'] = token.minSpendPerBuyer;
    //token['max_price'] = token.maxSpendPerBuyer;

    token['hard_cap'] = token.hard_cap;
    token['soft_cap'] = token.soft_cap;

    token['social_links'] = [];

    if (token.telegram) {
      token['social_links'].push({
        social: 'telegram',
        link: token.telegram
      });
    }

    if (token.twitter) {
      token['social_links'].push({
        social: 'twitter',
        link: token.twitter
      });
    }

    if (token.facebook) {
      token['social_links'].push({
        social: 'facebook',
        link: token.facebook
      });
    }

    if (token.discord) {
      token['social_links'].push({
        social: 'discord',
        link: token.discord
      });
    }

    //token['unlock_percent'] = 95;
    //token['safe_percent'] = 5;
    //token['unsold_action'] = 'Burn';
    token['presale_date_start'] = new Date(token.sale_start).toUTCString();
    token['presale_date_end'] = new Date(token.sale_end).toUTCString();
    token['listing_on'] = token.exchange;

    token['status_progress'] =
      token.status_flag == 1
        ? token.totalBaseCollected > token.hard_cap
          ? 'Filled'
          : 'In Progress'
        : token.status_flag == 0
        ? 'ended'
        : token.status_flag == 3
        ? 'Cancelled'
        : 'upcoming';

    const attributes = [
      /*[
			{
				title: 'Overview - SafeMoon',
				rating: 15.3,
				description_main: ['Our scanner has detected a significant risk that this token will be rugged or become a delayed honeypot. However, the scanner analysis determines the possibilities of the owners, not their intrntions.']
			},
			{
				title: 'RUG FULL SAFETY',
				rating: 0.0,
				description_list: ['Liquidity: unlocked (11.71%)', 'Holders: suspicious (13% of LP)', 'Top 5 Holders: 48.9% held', 'Mint: not detected', 'Unlock: SAFU Exclusive'],
				description_additional: 'Bt Med mura tied cae) smart-contract (and thus can be rugged at any time) is 11.71%: such a rate can be ENT OUR ule cecal wwe lei a Mea de ck Col ad smart-contract) is this address, which holds ee uke iNs'
			},
		],
		[
			{
				title: 'INFORMATION',
				description_main: ['Honeypot: no (simulation successfull)', 'Max TX: 1e+66', 'Max Sell: undefined', 'Buy Fees: 10%', 'Sell Fees: 10%', 'Buy Gas: 1,400,039', 'Sell Gas: 656,039']
			},
			{
				title: 'HONEYPOT SAFETY',
				rating: 24.9,
				description_list: ['Ownership: owned', 'Blacklist: not detected', 'Modify Max TX: yes', 'Modify Max Sell: not detected', 'Modify Fees: yes', 'Disable Trading: not detected'],
				description_additional: 'Bt Med mura tied cae) smart-contract (and thus can be rugged at any time) is 11.71%: such a rate can be ENT OUR ule cecal wwe lei a Mea de ck Col ad smart-contract) is this address, which holds ee uke iNs'
			},
		],
		*/
    ];

    token['attributes'] = attributes;
  }

  return token;
};

export const loadLockedTokens = (filterOnly, search) => (dispatch, useState) => {
  const list = [];
  for (let i = 0; i < 24; i++) {
    const name = tokens[faker.mersenne.rand(0, tokens.length - 1)];

    list.push({
      id: i + 1,
      name: name.toUpperCase(),
      icon: require(`../../theme/images/cryptoCoin/${name}.png`),
      amount: faker.mersenne.rand(1000, 1000000).toLocaleString('en-US').replaceAll(',', ' '),
      isUsersIdo: faker.datatype.boolean()
    });
  }
  dispatch(loadLockedTokensAction(list));
};

export const filterLockedTokens = (filterOnly, search) => (dispatch, useState) => {
  const lockedTokens = useState().coins.lockedTokens;

  let filterTokens = lockedTokens;
  if (filterOnly !== 'all') {
    filterTokens = filterTokens.filter((item) => item[filterOnly]);
  }
  if (!!search) {
    filterTokens = filterTokens.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  }
  return filterTokens;
};
