import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../theme/images/Logo.svg';
import walletSvg from '../../theme/images/wallet.svg';
import profile from '../../theme/images/profile.svg';
import { activateLogin } from '../../store/modals/operations';
import MobileNavigation from '../navigation/MobileNavigation';
import { useEthers } from '@usedapp/core';
import ChainsSelect from 'components/ChainsSelect/ChainsSelect';
import styles from './Header.module.scss';

import { StoreType } from 'store/store';
import { routes } from 'routes';

function Header() {
  const { user, chain } = useSelector(({ user, chain }: StoreType) => {
    return {
      user,
      chain
    };
  });
  const dispatch = useDispatch();
  const { deactivate } = useEthers();

  const location = useLocation();

  const isLanding = location.pathname === '/';

  let nav;
  if (isLanding) {
    nav = (
      <>
        <Link className="menuLogo" to={routes.home}>
          <img src={Logo} alt="logo" />
        </Link>
        {
          <div>
            <Link to="tokensList" className="tokenButton">
              Tokens List
            </Link>
            <Link to={user.isLogin ? routes.dashboard : routes.auth} className="buttonDefault">
              Get started
            </Link>
          </div>
        }
      </>
    );
  } else {
    nav = (
      <>
        <div className="menuLeftSide">
          <Link className="logoIcon" to={routes.home}>
            <img src={Logo} alt="logo" />
          </Link>
          <MobileNavigation />
        </div>
        <div className="rightMenuExpended">
          {chain.data && (
            <div className={styles.chainWrapper}>
              <div className={styles.BSCButton}>
                <img src={chain.data.image} alt={chain.data.name} />
                <span>{chain.data.name}</span>
              </div>
              <ChainsSelect />
            </div>
          )}

          <button
            className={styles.keyWalletButton}
            onClick={() => {
              if (user.isLogin) {
                const confirm = window.confirm('Disconnect wallet?');

                if (confirm) {
                  deactivate();
                }
                return;
              }

              // @ts-ignore
              dispatch(activateLogin());
            }}
          >
            <img src={walletSvg} alt="Wallet icon" />
            <span>
              {user.isLogin ? (
                <>
                  {user.user.wallet_address.slice(0, 4)}...{user.user.wallet_address.slice(-4)}
                </>
              ) : (
                'Connect wallet'
              )}
            </span>
          </button>

          {user.isLogin && (
            <Link className="profileWrap" to="account">
              <img src={profile} alt="Account logo" />
            </Link>
          )}
        </div>
      </>
    );
  }

  return (
    <nav className="menu">
      <div className="container flexBetweenCenter">{nav}</div>
    </nav>
  );
}

export default memo(Header);
