import { closeAll, activeLogin } from './actions';

export const closeAllModals = () => (dispatch, getState) => {
  const all_modals = getState().modals;

  for (const key in all_modals) {
    all_modals[key] = false;
  }

  dispatch(closeAll(all_modals));
};

export const activateLogin = () => (dispatch) => {
  dispatch(activeLogin());
};
