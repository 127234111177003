import React, { useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadTopCoins } from '../store/coins/operations';
import trending from '../theme/images/trending.svg';

function TopCoins({ className }) {
  const topCoins = useSelector((st) => st.coins.top_coins);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTopCoins());
  }, [dispatch]);

  return (
    <article className={className}>
      {/*<div className="marqueeHead">
				<div className="tradingWrap">
					<img src={trending} alt='Trending' />
				</div>
				Trending
			</div>
			*/}

      {!!topCoins.length && (
        <div className="marquee-container-default">
          <Marquee speed={30} pauseOnHover>
            {topCoins.map((item, index) => {
              return (
                <Link className="marqueeItem" key={item.id} to="#">
                  <span className="marqueeItemNumber">#{index + 1}</span>
                  {item.name}
                </Link>
              );
            })}
          </Marquee>
        </div>
      )}
    </article>
  );
}

export default TopCoins;
